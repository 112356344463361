function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

async function listen(port, gotData) {
    console.log('listening on port...');
    while (port.readable) {
        const reader = port.readable.getReader();
        const decoder = new TextDecoder();

        let scanned = '';
        try {
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    // Allow the serial port to be closed later.
                    reader.releaseLock();
                    console.log(scanned);
                    if (gotData) gotData(scanned);
                    else console.log('no gotData passed to listen. Ignoring the scanned value.');
                    break;
                }
                if (value) {
//                    console.log('value', value);
                    const decoded = decoder.decode(value)
//                    console.log('decoded', decoded);
                    scanned += decoded;
                    
                    const len = scanned.length;
                    if (len > 4 && scanned[len - 2] === '\x03' && scanned[len - 1] === '\x04') {
                        console.log('scanned', scanned);
                        const unwrapped = scanned.slice(2, len - 3);
                        if (gotData) gotData(unwrapped);
                        else console.log('no gotData was passed to listen. Ignoring the scanned value.');
                        console.log('unwrapped', unwrapped);
                        reader.releaseLock();
                        break;
                    }
                }
            }
        } catch (error) {
            console.log(error, 'scanned so far', scanned);
        }
    }
}
const port = {
    pair: async function (got, gotData) {
        let device;
        try {
            console.log('get acces to the Reader Device...');
            const dlScanner = {
                usbVendorId: 0x0403,// FTD
            };
            const filter = { filters: [dlScanner] };
            device = await navigator.serial.requestPort(filter);
            if (device) {
                var opened = await device.open({ baudRate: 9600 });
                console.log('opened', opened);
                got(device);
                listen(device, gotData);
//                device.onconnect = got;
               // device.ondisconnect = got;


            }
        } catch (err) {
            console.log('getDevices error', err);
            // No device was selected.
        }

        if (device !== undefined) {
            // Add |device| to the UI.
            console.log(device);
//            claim(device);
        }
    },
    getPorts: async function (got, add, del, gotData) {
        console.log('getPorts...');
        let ports = await navigator.serial.getPorts();
        ports.forEach(async device => {
            console.log(device);
//            console.log('found usb device', device.serialNumber, device.productName);
            got(device);
            listen(device);
//            await claim(device);
        });

        console.log('addEventListener connect...');
        navigator.serial.addEventListener('connect', event => {
            const device = event.target;
            console.log(device, 'is connected');
            add(device);
            listen(device);
        });
        console.log('addEventListener disconnect...');
        navigator.serial.addEventListener('disconnect', event => {
            const device = event.target;
            console.log(device, 'was disconnected');
            del(device);
        });
    }
}

export default port;

