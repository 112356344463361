import env from '../tag';
function getHeaders() {
	return {
		'Content-Type': 'application/json',
		'apikey': 'Bq5UXwkPGxhZWXIXWWYHru0Upe2SiPMY',
	}
}
const url = '/api/labq';// todo: add environment config?
//function sleep(ms) {
//	return new Promise(resolve => setTimeout(resolve, ms));
//}

async function validateLabCode(labCode, validate, locationCode) {
	const target = `${url}/lab/${labCode}/${validate||false}/${locationCode||''}`;
	console.log('get', target);
	const response = await fetch(target);
	console.log('response:', response);
	if (!response.ok) throw new Error('Failed to validate barcode/specimen ID');
	const json = await response.json();
	console.log('json:', json);
	return json;
}
async function getLocation(code) {
	const target = `${url}/${code}`;
	console.log('get', target);
	const response = await fetch(target);
	console.log('response:', response);
	if (!response.ok) throw new Error(`The location code is not valid: ${code}`);
	const json = await response.json();
	console.log('json:', json);
	return json;
}
export async function getResult(code) {
	console.log("getResult", code);
	const target = `${url}/result/${code}`;
	console.log('get', target);

//	await sleep(1000);

	if (code === "TESTXXX1")
		return { found: true, ready: false };
	if (code === "TESTXXXN")
		return { found: true, ready: true, result: 'Negative' };
	if (code === "TESTXXXP")
		return { found: true, ready: true, result: 'Positive' };
	if (code === "TESTXXXI")
		return { found: true, ready: true, result: 'Inconclusive' };
	if (code === "TESTXXX4")
		return { found: false };
	if (code === "TESTXXXE")
		throw new Error("simulated error message");

	try {
		const response = await fetch(target);
		const result = await response.json();
		console.log('result', result);
		return result;
    }
	catch (error)
	{
		console.error('getResult Error:', error);
		throw error;
	}
}
export async function postFile(file, field, id) {
//	console.log("postFile", field, file.type, id);
	const target = `${url}/${id.toString()}/${field}`;
//	console.log('Upload to', target);
	const formData = new FormData();
	formData.append('file', file);
	const result = await fetch(target, {
		method: 'POST',
		body: formData
	});
	return result;
}
async function post(data = {}) {
	console.log("POST", data);
	const response = await fetch(url, {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json'
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
//	console.log(response);
	var json = await response.json(); 
	if (response.ok) {
		return json;
	}
	console.log(json);
//	throw new Error(`Failed to register. ${response.statusText}`); // generic statusText BadRequest or Internal Server Error
	throw new Error(`Failed to register. ${json.detail || response.statusText || ''}`);
//	throw new Error((json.title || '') + ' ' + (json.detail || ''));
}
async function retest(data = {}) {
	console.log("retest POST", data);
	const response = await fetch(`${url}/retest`, {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	console.log(response);
	var json = await response.json(); // parses JSON response into native JavaScript objects
	if (response.ok) {
		return json;
	}
	console.log(json);
	throw new Error((json.title || '') + ' ' + (json.detail || ''));
	//	return null;
}

  async function put(url = '', data = {}) {
	console.log(data);
	// Default options are marked with *
	const response = await fetch(url, {
	  method: 'PUT', // *GET, POST, PUT, DELETE, etc.
	  mode: 'cors', // no-cors, *cors, same-origin
	  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	  credentials: 'same-origin', // include, *same-origin, omit
	  headers: {
		'Content-Type': 'application/json'
		// 'Content-Type': 'application/x-www-form-urlencoded',
	  },
	  redirect: 'follow', // manual, *follow, error
	  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	  body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	console.log(response);
	return response.json(); // parses JSON response into native JavaScript objects
}

async function getAppointments(location, date) {
	const cairnAPI = (await env.get()).CairnAPI;
//	console.log('cairnAPI :', cairnAPI);
	const target = `${cairnAPI}/api/Lab/getAppointments`;
	//	const target = `${url}/appointments`;
//	console.log('getAppointments:', target, location, date);
	const response = await fetch(target, {
		headers: getHeaders(),
		method: 'POST',
		body: JSON.stringify({ location: location, appointmentDate: date })
	});
//	console.log('response:', response);
	if (!response.ok) {// this API returns status 200 and uses StatusCode to indicate errors
		throw new Error(`Failed to get appointments for the location: ${location}.`);
	}
	const json = await response.json();
//	console.log('json:', json);
	if (json.StatusCode > 299) {// this API returns status 200 and uses StatusCode to indicate errors
		throw new Error(`Please, check the location and date you entered. ${json.Message}`);
	}
	return json;
}

async function getConsent(location) {
	const target = `${(await env.get()).CairnAPI}/api/Lab/GetLocationConsent/${location}`;
	console.log('getConsent:', target);
	const response = await fetch(target, {
		headers: getHeaders(),
	});
	console.log('response:', response);
	if (!response.ok) {// this API returns status 200 and uses StatusCode to indicate errors
		throw new Error(`Failed to get appointments for the location: ${location}.`);
	}
	const json = await response.json();
	console.log('json:', json);
	if (json.StatusCode > 299) {// this API returns status 200 and uses StatusCode to indicate errors
		throw new Error(`Please, check the location you entered.`);
	}
	return json;
}

async function getPayers(location) {
	if (!location) {
		console.log('getPayers', 'the location is required');
		return [];
	}
	const target = `${(await env.get()).CairnAPI}/api/Lab/getPayers/${location}`;
	console.log(target);
	const response = await fetch(target, {
		headers: getHeaders(),
	});
	console.log('response:', response);
	if (!response.ok) {// this API returns status 200 and uses StatusCode to indicate errors
		throw new Error(`Failed to get Payers for: ${location}.`);
	}
	const json = await response.json();
	console.log('json:', json);
	if (json.StatusCode > 299) {// this API returns status 200 and uses StatusCode to indicate errors
		console.log(json.Message);
		throw new Error(`Please, check the location you entered: ${location}`);
	}
	return json.Payers;
}

export default { post, put, getLocation, validateLabCode, retest, getAppointments, getConsent, getPayers};
