function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
async function claim(device) {
    try {
        console.log('using device...');
        if (device.opened) {
            console.log('device', device.productName, 'is already open');
            return;
        }
        console.log('open device...');
        await device.open();
        console.log('device is open...', device);
        if (device.configuration === null) {
            console.log('selectConfiguration...');
            await device.selectConfiguration(1);
        }
        else {
//            console.log('device.configuration', device.configuration);
        }
        console.log('claimInterface...');
        await device.claimInterface(0);
//        const result = await device.controlTransferOut({
//            requestType: 'vendor', recipient: 'device',
//            request: 0xE1,// init camera
//            value: 0x00, index: 0x00
//        }, (new TextEncoder()).encode('\x01<SERIAL:>\x04'));
////            (new TextEncoder()).encode(String.fromCharCode(1) + '<SERIAL:>' + String.fromCharCode(4)))
//        console.log('transferOut: get serial...');
//        const command = (new TextEncoder()).encode('\x01<SERIAL:>\x04');
        const command = (new TextEncoder()).encode('\x01<SETPAR:>\x04');
//        console.log(command);
        console.log((new TextDecoder()).decode(command));
        //showBytes(command);
        console.log('transferOut... ep2');
        const result = await device.transferOut(2, command);
        console.log('transferOut result', result);

        function showBytes(data, length) {
            if (!data) {
                console.log('empty');
                return;
            } 
            const len = length || data.length;
            console.log("length", len);
            let index = 0;
            while (index < len)
                console.log("byte", index,
                    data.getUint8(index++));
        }

        async function listen() {
            console.log('device.transferIn(1...');
            let result = await device.transferIn(1, 64);
            if (result.status === 'stall') {
                console.log("clearHalt due to transfer status", result.status);
                device.clearHalt(1);
            }
            if (result.status === 'ok') {
                const length = result.data.byteLength;
                if (length === 2) {
//                    console.log(i, "raw data", result.data.getUint8(0));
//                    console.log(i, "raw data", result.data.getUint8(1));
                    //// report waiting for scan to have page prompt user to scan
                }
                showBytes(result.data, length)
                var string = new TextDecoder().decode(result.data.buffer);
                console.log("utf-8 decoded data", string);
                await sleep(1000);
  //              listen();
            }
        }
//        console.log("finished reading");
        listen();
    }
    catch (e) {
        console.log(e)
    }
}
let usb = {
    pair: async function (got) {
        let device;
        try {
            console.log('get acces to the Reader Device...');
            const dlScanner = {
                vendorId: 0x0403,// FTD
                productId: 0x6001, //PID
            };
            const eSeek = {
                vendorId: 0x28A6
            };
            const filter = { filters: [dlScanner] };
            device = await navigator.usb.requestDevice(filter);
        } catch (err) {
            console.log('getDevices error', err);
            // No device was selected.
        }

        if (device !== undefined) {
            // Add |device| to the UI.
            console.log(device);
            got(device);
//            claim(device);
        }
    },
    //https://wicg.github.io/webusb/#enumeration
    getDevices: async function (got, add, del, gotData) {
        console.log('getDevices...');
        let devices = await navigator.usb.getDevices();
        devices.forEach(async device => {
            console.log('found usb device', device.serialNumber, device.productName);
            console.log(device);
            got(device);
            await claim(device);
        });

        console.log('addEventListener connect...');
        navigator.usb.addEventListener('connect', event => {
            const device = event.device
            console.log(device.serialNumber, 'is connected');
            add(device);
        });
        console.log('addEventListener disconnect...');
        navigator.usb.addEventListener('disconnect', event => {
            const device = event.device
            console.log(device.serialNumber, 'was disconnected');
            del(device);
        });
    }
}

export default usb;