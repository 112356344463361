import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
//import { FetchData } from './components/FetchData';
import Patient, { Start, Appointment, Symptoms, Insured, Complete, Prompt, Retest, Specimen, Usb } from './components/Counter';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/retest' component={Retest} />
            <Route path='/specimen' component={Specimen} />
            <Switch>
                <Route path='/register/:location' component={Start} />
                <Route path='/register' component={Start} />
            </Switch>
            <Route path='/appointment' component={Appointment} />
            <Route path='/patient' component={Patient} />
            <Route path='/symptoms' component={Symptoms} />
            <Route path='/insured' component={Insured} />
            <Route path='/complete' component={Complete} />
            <Route path='/results' component={Prompt} />
            <Route path='/usb' component={Usb} />
      </Layout>
    );
  }
}
