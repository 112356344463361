const Insurance = {
    otherInsurance: 'OTHER INSURANCE',
    noInsurance: 'NO HEALTH INSURANCE',
}
const Constants = 
{
    ...Insurance,
	maxFileSize: 6000000,
	usStates: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE',
		'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA',
		'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS',
		'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
		'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD',
		'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'],
    insurers: ['AETNA', 'AMBETTER', 'ANTHEM / BCBS', 'BCBS FEDERAL', 'CIGNA',
        'CORE ADMIN SERV', 'GEHA', 'GOLDEN RULE', 'GREAT WEST',
        'HUMANA', 'MEDICARE', 'MERITAIN HEALTH', 'RAILROAD MEDICARE', 'SECURE HEALTH',
        'TRICARE', 'UNITED HEALTHCARE', 'WELLCARE', 'MEDICAID', 'SELF PAY',
        Insurance.otherInsurance, Insurance.noInsurance
    ],
    areyou: ['Student/Faculty', 'Public'],
    races: [
        { id: 'U', name: 'Unknown' },
        {id: 'B', name: 'African American'},
        {id: 'A', name: 'Asian'},
        {id: 'C', name: 'Caucasian'},
        {id: 'H', name: 'Hispanic'},
        {id: 'I', name: 'Native American'},
    ],

    relToInsured: [
        { code: 'I', name: 'Self' },
        { code: 'S', name: 'Spouse' },
        { code: 'C', name: 'Child' },
        { code: 'D', name: 'Daughter' },
        { code: 'F', name: 'Father' },
        { code: 'R', name: 'Friend' },
        { code: 'G', name: 'Grandparent' },
        { code: 'M', name: 'Mother' },
        { code: 'B', name: 'Sibling' },
        { code: 'N', name: 'Son' },
        { code: 'O', name: 'Other' },
    ],
}

export default Constants;